<template>
  <div>
    <Navbar :page="!$store.state.user.cliente ? 'Gestão de riscos' : 'Analisar Riscos'" video="https://youtu.be/HPaHssvGRBA"/>
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <div v-if="total > 0 " class="grid grid-cols-12 gap-6 mb-3 items-end justify-between">
        <div class="col-span-12 md:col-span-4 rounded-lg px-1 py-2">
            <apexchart height="240" type="donut" :options="optionsGeral" :series="seriesGeral"></apexchart>
        </div>
        <div class="col-span-12 md:col-span-3 lg:col-span-8 flex justify-end">
            <a 
              :href="`${url_api}/v1/${route}/exportar/relatorioExcel?token=${$store.state.token}&&empresa=${this.$store.state.empresa._id}&&nome=${this.$store.state.empresa.nome || ''}`"
              rel="noreferrer" 
              class="w-full md:w-auto lg:w-auto bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 focus:shadow-sm focus:ring-4 text-white flex gap-2 py-2 items-end px-8 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold justify-center"
            >
                <svg class=" h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256">
                  <path d="M92,132H40a12,12,0,0,1-8.81-3.91,12.2,12.2,0,0,1-3.14-9.31A99.82,99.82,0,0,1,70,46.2,12,12,0,0,1,87.29,50l25.94,45a4,4,0,0,1-6.92,4L80.36,54a4,4,0,0,0-5.78-1.27A91.83,91.83,0,0,0,36,119.5a4.2,4.2,0,0,0,1.08,3.2A3.9,3.9,0,0,0,40,124H92a4,4,0,0,1,0,8Zm136-13.22A99.82,99.82,0,0,0,186,46.2,12,12,0,0,0,168.72,50L142.77,95a4,4,0,0,0,6.92,4L175.64,54a4,4,0,0,1,5.78-1.27A91.83,91.83,0,0,1,220,119.5a4.2,4.2,0,0,1-1.08,3.2A3.9,3.9,0,0,1,216,124H164a4,4,0,0,0,0,8h52a12,12,0,0,0,8.81-3.91A12.2,12.2,0,0,0,228,118.78Zm-78.48,38.4a4,4,0,0,0-6.93,4l25.82,44.73a4,4,0,0,1,.35,3.22,3.9,3.9,0,0,1-2.12,2.41,92.24,92.24,0,0,1-77.18,0,3.9,3.9,0,0,1-2.12-2.41,4,4,0,0,1,.35-3.22l25.82-44.73a4,4,0,0,0-6.93-4L80.71,201.91a12,12,0,0,0,5.34,16.89,100.24,100.24,0,0,0,83.9,0,12,12,0,0,0,5.34-16.89ZM128,136a8,8,0,1,0-8-8A8,8,0,0,0,128,136Z"></path>
                </svg>
                Baixar riscos 
            </a>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 md:col-span-2 mb-1 mt-1">
          <select v-model="filtro.setor" name="setor" id="setor" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option default :value="null">Filtrar por setor</option>
              <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
          </select>
        </div>
        <div class="col-span-12 md:col-span-4 mb-1 mt-1">
          <select v-model="filtro.pessoa" name="pessoa" id="pessoa" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option default :value="null">Filtrar por responsável</option>
              <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
          </select>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-1">
          <select v-model="filtro.status" name="status" id="status" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option default :value="null">Filtrar por status</option>
              <option value="Não Analisada">Não Analisada</option>
              <option value="Em Análise">Em Análise</option>
              <option value="Analisada pelo responsável">Analisada pelo responsável</option>
              <option value="Revisada pelo consultor">Revisada pelo consultor</option>
              <option value="Finalizado">Finalizado</option>
          </select>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-1">
          <select v-model="filtro.grauDeRisco" name="grauDeRisco" id="grauDeRisco" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option default :value="null">Filtrar por grau de risco</option>
              <option value="muitobaixa">Muito Baixo</option>
              <option value="baixo">Baixo</option>
              <option value="medio">Médio</option>
              <option value="alto">Alto</option>
              <option value="muitoalto">Muito Alto</option>
          </select>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <button @click="updateFiltro" class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      REF
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Atividade / processo
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Setor
                    </th>
                    <th v-if="!$store.state.user.cliente" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Responsável
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Grau de risco
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Status
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Data atualização
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id" :class="item.respondido && $store.state.user.cliente || item.finalizado ? 'bg-blue-100': '' ">
                    <td class="px-3 py-4">
                      <div class="flex items-center">
                        <div class="text-sm_2 text-gray-500">
                          {{item.ref ? item.ref : ''}}
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 ">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.nome ? item.nome : item.atividade && item.atividade.nome ? item.atividade.nome : ''}}
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 ">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{item.atividade && item.atividade.setor && item.atividade.setor.nome ? item.atividade.setor.nome : ''}}
                        </div>
                      </div>
                    </td>
                    <td v-if="!$store.state.user.cliente" class="px-6 py-4 text-sm font-medium text-gray-500">
                      <span v-if="item.gerada">{{ item.responsavel ? item.responsavel.nome : 'Consultor' }}</span>
                      <span v-else>{{ item.responsavel ? item.responsavel.nome : '' }}</span>
                    </td>
                    <td class="px-2 py-3 text-sm text-center">
                      <span class="px-1 bg-green-500 text-white" v-if="item.grauDeRisco === 'muitobaixa'">Muito Baixo</span>
                      <span class="px-1 bg-green-400 text-white" v-if="item.grauDeRisco === 'baixo'">Baixo</span>
                      <span class="px-1 bg-yellow-500 text-white" v-if="item.grauDeRisco === 'medio'">Médio</span>
                      <span class="px-1 bg-red-400 text-white" v-if="item.grauDeRisco === 'alto'">Alto</span>
                      <span class="px-1 bg-red-500 text-white" v-if="item.grauDeRisco === 'muitoalto'">Muito Alto</span>
                    </td>
                    <td class="px-6 py-4">
                      <div class="text-center">
                          <div v-if="item.status === 'Não Analisada'" class="text-sm font-semibold text-pendente">
                            {{ item.status ? item.status: ''}}
                          </div>
                          <div v-else-if="item.status === 'Em Análise'" class="text-sm font-semibold text-andamento">
                            {{ item.status ? item.status: ''}}
                          </div>
                          <div v-else-if="item.status === 'Finalizado'" class="text-sm font-semibold text-green-500">
                            {{ item.status ? item.status: ''}}
                          </div>
                          <div v-else class="text-sm font-semibold text-blue-500">
                            {{ item.status ? item.status: ''}}
                          </div>
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <div class="flex items-center">
                        <div v-if="item.dataAtualizacao" class="text-sm text-gray-500">
                          {{ item.dataAtualizacao | moment("DD/MM/YYYY HH:mm")}}
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 text-sm font-medium">
                      <button v-if="!item.gerada" @click="modalEnviar(item)" class="text-white hover:bg-green-700 bg-green-600 rounded py-1 px-4 mx-3">
                        Enviar
                      </button>
                      <span v-if="item.gerada && !$store.state.user.cliente" class="mr-2 text-gray-500"> Atividade Enviada</span>
                      <router-link 
                      :to="`/${route}/form/${item._id}`"
                      class="inline-block text-white hover:bg-blue-500 bg-blue-700 rounded py-1 px-4 mx-1">
                      {{ getNameFuncao(item) }}
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
    <modal name="enviarRisco" :adaptive="true" :height="'auto'">
      <div class="px-5 py-5">
        <h2 class="text-xl font-bold mb-5 text-gray-800"> Enviar análise para responsável </h2>
        <label for="responsavel" class="block text-sm font-medium">
          Responsável pela gestão de risco                   
        </label>
        <select v-model="form.responsavel" name="responsavel" id="responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
          <option :value="null">Marcar consultor como responsável</option>
          <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
        </select>
        <button @click="gerarParaResponsavel(form)" class="text-white hover:bg-green-700 bg-green-600 rounded py-1 px-4 my-4">
            Enviar análise
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      route: 'riscos',
      form: {
        responsavel: null,
      },
      optionsGeral: {
        chart: {
          id: 'chartGeral',
          type: 'donut',
        },
        labels: ["Não analisada", "Analisada / Revisada", "Em análise"]
      },
      seriesGeral: [0, 0],
      list: [],
      page: 1,
      perPage: 10,
      setores: [],
      pessoas: [],
      total: 0,
      query: { page: 1, limit: 10, skip: 0  },

      filtro: {
        setor: null,
        pessoa: null,
        status: null,
        grauDeRisco: null,
      }
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { filtro: this.filtro, skip: this.query.skip, limit: this.query.limit  });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async grafico() {
      const resReq = await this.$http.post(`/v1/${this.route}/resumo`);
      this.seriesGeral = [resReq.data.naoanalisada, resReq.data.analisada, resReq.data.emanalise];
    },
    
    async remove(id) {
      await this.$http.delete(`/v1/${this.route}/${id}`);
      this.$vToastify.success("Removido com sucesso!");
      this.start();
    },

    async modalEnviar(item){
      this.form = item;
      this.$modal.show('enviarRisco');
    },

    async gerarParaResponsavel(risco) {
      this.$modal.hide('enviarRisco');
      if(!risco.responsavel){
          this.$confirm({
          title: 'Consultor responsável',
          message: `Deseja marcar o consultor como responsável ?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if(!confirm) return;
            await this.$http.put(`/v1/${this.route}/`,{ _id: risco._id, gerada: true, responsavel: null, respondido: true });
            this.start();
            this.grafico();
          }
        })
        return
      }

      this.$confirm({
        title: 'Enviar questionário',
        message: `Deseja enviar o questionário para o responsável ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.put(`/v1/${this.route}/`,{ _id: risco._id, gerada: true, responsavel: risco.responsavel });
          this.start();
          this.grafico();
        }
      })
    },

    getNameFuncao(item){
      if(this.$store.state.user.cliente) {
        if(item.respondido) {
          return 'Atualizar' 
        }
        else {
          return 'Analisar';
        }
      } else {
        if((item.responsavel === null || item.responsavel === undefined) && !item.revisado) return 'Iniciar';
        
        if(item.revisado) {
          return 'Atualizar';
        } else {
          if(!item.respondido) {
            return 'Atualizar';
          } else {
            return 'Revisar';
          }
        }
      }
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { 
        page: this.query.page, 
        limit: this.query.limit, 
        skip: this.query.skip, 
        filtro: JSON.stringify(this.filtro),
        t: moment().format('x'),
      };

      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.filtro = JSON.stringify(this.filtro);

      this.$router.push({ path: `/${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.filtro) this.filtro = JSON.parse(this.$route.query.filtro);
    
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.filtro) this.filtro = JSON.parse(this.$route.query.filtro);

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.grafico();
    this.start();

    const listSetores = await this.$http.post(`/v1/setores/list`, { all: true });
    this.setores = listSetores.data.data;

    const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true});
    this.pessoas = listPessoas.data.data;
  },
}
</script>